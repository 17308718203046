<script>
import { mapGetters, mapActions } from "vuex";
import { login } from "../../api";

export default {
	name: "login",
	data () {
		return {
			errorMessage:""
		};
	},
	computed: {
		...mapGetters("session", ["username", "validSession"])
	},
	methods: {
		...mapActions("session", ["setUsername", "setUserLoggedIn", "setValidSession"]),
		tryLogin() {
			const password = document.getElementById("passwordInputField").value;
			if (!this.username) {
				return this.errorMessage = "* Username is empty";
			} else if (!password) {
				return this.errorMessage = "* Password is empty";
			}
			return login({ username: this.username, password })
				.then(() => {
					this.errorMessage = "";
					return this.setUserLoggedIn()
				})
				.catch(err => {
					if (err.status === 401) {
						this.errorMessage = "* Wrong username or password, Please try again";
					} else
						this.errorMessage = err.error;
				});
		},
	},
	mounted() {
		if (this.$route.query.username) {
			this.setUsername(this.$route.query.username);
		}
	}
};
</script>

<template>
	<v-app id="inspire">
		<v-container
			fluid fill-height
			style="width:1800px"
		>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md5>
					<v-card class="elevation-12 px-4 py-4">
						<div>
							<div class="pb-4">
								<img
									src="../../../../resources/kongsberg_horizontal_light.png"
									width="50%"
								/>
							</div>
							<v-form @submit.prevent ="tryLogin">
								<v-text-field
									:value="username"
									@input="setUsername"
									label="Username"
									prepend-inner-icon="fa fa-user"
									outlined
									type="text"
									required
									autofocus
								/>
								<v-text-field
									label="Password"
									prepend-inner-icon="fa fa-lock"
									outlined
									type="password"
									@keyup.enter="tryLogin"
									id="passwordInputField"
								/>
							</v-form>
							<v-btn
								block
								large
								type ="submit"
								color="primary"
								@click="tryLogin()"
							>
								Login
							</v-btn>
							<div class="error-color py-2">{{ errorMessage }}</div>
							<router-link
								:to="{name: 'resetPassword'}"
								color="primary"
							>
								Forgot username or password?
							</router-link>
						</div>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<style lang="scss" scoped>
.error-color {
	color: red;
}
</style>