import "font-awesome/css/font-awesome.min.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
	icons: {
		iconFont: "fa4" || "mdi" || "mdiSvg"
	},
	theme: {
		dark: true,
		themes: {
			light: {
				background: "#121212",
				navigation: "#ffffff",
				blueButtons: "#001639",
				blueButtonsLight: "#0047b8",
				kongsbergBlue: "#001639",
				androidNavBar: "#ffffff",
				selectColor: "#001639",
				textColor: "#000000",
				secondaryTextColor: "#ffffff",
				menuRightButton: "#001639",
				scrollArrow: "#001639",
				searchResultButton: "#F8F8F8"
			},
			dark: {
				background: "#121212",
				navigation: "#0f0f0f",
				blueButtons: "#ffffff",
				blueButtonsLight: "#ffffff",
				kongsbergBlue: "#001639",
				androidNavBar: "#0f0f0f",
				selectColor: "#ffffff",
				textColor: "#ffffff",
				secondaryTextColor: "#000000",
				menuRightButton: "#ffffff",
				scrollArrow: "#43567f",
				searchResultButton: "#282828"
			}
		}
	}
};

export default new Vuetify(opts);
