import extensionsConfig from "../../resources/extensionsConfig.json";
export default {
	data: () => ({
		extensionsConfig
	}),
	methods: {
		getBackgroundColor(rootExtension) {
			if (this.extensionsConfig[rootExtension]) {
				return this.extensionsConfig[rootExtension].color;
			}
			return "rgba(237, 237, 237, 0.8)";
		}
	}
};
