<script>
import { mdiMoonWaningCrescent, mdiWhiteBalanceSunny, mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
export default {
	$dbQuery: {
		darkModeSettings() {
			return {
				default: {},
				selector: {
					id: "darkModeSettings"
				},
				limit: 1,
				onLoad: () => {
					this.loadingTheme = false;
				},
				db: this.$_dbCallAppConfig.name
			};
		}
	},
	data: () => ({
		loadingTheme: true,
		updatingTheme: false,
		icons: {
			mdiMoonWaningCrescent,
			mdiWhiteBalanceSunny,
			mdiToggleSwitch,
			mdiToggleSwitchOffOutline
		}
	}),
	computed: {
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].kongsbergBlue;
		},
		togglerIcon() {
			return this.darkModeSettings.darkMode ? this.icons.mdiToggleSwitch : this.icons.mdiToggleSwitchOffOutline;
		},
		themeIcon() {
			return this.$_theme === "dark" ? this.icons.mdiMoonWaningCrescent : this.icons.mdiWhiteBalanceSunny;
		},
		themeIconColor() {
			return this.$_theme === "dark" ? "white" : this.btnColor;
		},
		toggleThemeText() {
			return this.$_theme === "dark" ? "A bit too dark? Try light mode!" : "A bit too bright? Try dark mode!";
		}
	},
	methods: {
		toggleTheme() {
			const newValue = !this.darkModeSettings.darkMode;
			this.updatingTheme = true;
			const darkModeSettingsCopy = JSON.parse(JSON.stringify(this.darkModeSettings));
			darkModeSettingsCopy.darkMode = newValue;
			this.$_dbCallAppConfig.upsert(darkModeSettingsCopy._id, darkModeSettingsCopy)
				.then(() => {
					this.updatingTheme = false;
				})
				.catch(() => {
					this.updatingTheme = false;
				});
		}
	}
};
</script>

<template>
	<div>
		<v-card
			class="mt-4 mx-1 py-2"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
		>
			<v-card-actions
				v-if="!loadingTheme"
				class="py-0"
			>
				<p class="subtitle-1 mb-0 ml-1">
					{{ toggleThemeText }}
				</p>
				<v-spacer />
				<v-icon
					class="mx-1"
					:color="themeIconColor"
				>
					{{ themeIcon }}
				</v-icon>
				<v-btn
					icon
					:ripple="false"
					@click.stop="toggleTheme"
				>
					<v-icon
						size="36"
						:color="themeIconColor"
					>
						{{ togglerIcon }}
					</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
