import * as api from "../../api";

// actions
const actions = {};

for (let key in api) {
	actions[key] = function({ dispatch, rootGetters }, apiArgs) {
		const apiFunc = api[key];
		const options = {
			dispatch,
			rootGetters,
			apiFunc,
			apiArgs
		};
		return runAction.call(options);
	};
}

function runAction() {
	return runRequest.call(this)
		.catch(error => {
			if (error.status === 401) {
				return handleUnauthorizedError.call(this);
			} else {
				throw error;
			}
		});
}

function runRequest() {
	const serverUrl = this.rootGetters["env/serverUrl"];
	return this.apiFunc(serverUrl, this.apiArgs);
}

function handleUnauthorizedError() {
	return api.refreshToken()
		.then(() => runRequest.call(this))
		.catch(error => {
			if (error.status === 401) {
				return this.dispatch("session/setUserLoggedOut", null, { root: true });
			} else {
				throw error;
			}
		});
}

export default {
	namespaced: true,
	actions
};
