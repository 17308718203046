import Vue from "vue";
import { UPDATE_EXTENSIONS, UPDATE_SYNONYMS, UPDATE_ACTIVE_KEY } from "../mutation-types";
import { phoneMenuKey } from "@dashboard/utils/app-state-keys";

const state = {
	extensions: [],
	synonyms: [],
	activeExtensionKey: "",
	activeSynonymKey: ""
};

// getters
const getters = {
	extensions: state => state.extensions,
	synonyms: state => state.synonyms,
	currentActiveKey: state => (type) => type === phoneMenuKey ? state.activeExtensionKey : state.activeSynonymKey
};

// actions
const actions = {
	getAllExtensions({ dispatch, commit }) {
		return dispatch("request/getExtensions", null, { root: true })
			.then(result => commit(UPDATE_EXTENSIONS, result))
	},
	getAllSynonyms({ dispatch, commit }) {
		return dispatch("request/getSynonyms", null, { root: true })
			.then(result => commit(UPDATE_SYNONYMS, result))
	},
	updateCurrentActiveKey({ dispatch, commit }, type) {
		return dispatch("request/getContent", type, { root: true })
			.then(active => commit(UPDATE_ACTIVE_KEY, { type, active }))
	}
};

// mutations
const mutations = {
	[UPDATE_EXTENSIONS](state, result) {
		state.extensions = result;
	},
	[UPDATE_SYNONYMS](state, result) {
		state.synonyms = result;
	},
	[UPDATE_ACTIVE_KEY](state, { type, active }) {
		if (type === phoneMenuKey) {
			state.activeExtensionKey = active._key;
		} else {
			state.activeSynonymKey = active._key;
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
