// A bug in pouchdb prevents me from adding more indexes.
// The bug causes the query to break if there is an index on one of
// the selectors and that selector uses the $in operator
import utils from "../utils";
const indexes = ["_id"];
const dbNames = utils.dbNames;
export default {
	install(Vue) {
		const defineReactive = Vue.util.defineReactive;
		defineReactive(Vue.prototype, "$_dbCallHistory", {});
		defineReactive(Vue.prototype, "$_dbCallAppConfig", {});

		Vue.mixin({
			beforeCreate() {
				this.$_dbCallHistory = {
					name: dbNames.historyDbName,
					...this.$bindDbApi(dbNames.historyDbName)
				};
				this.$_dbCallAppConfig = {
					name: dbNames.callConfigDbName,
					...this.$bindDbApi(dbNames.callConfigDbName)
				};
				return this.$_dbCallHistory.createIndex({
					index: {
						fields: indexes
					}
				})
					.then(() => {
						return this.$_dbCallAppConfig.get("node/configuration")
							.then(
								value => { },
								() => this.$_dbCallAppConfig.put({
									_id: "node/configuration",
									id: "configuration",
									defaultNumber: "",
									region: ""
								}));
					})
					.then(() => {
						return this.$_dbCallAppConfig.get("node/welcomeMessageConfig")
							.then(
								value => { },
								() => this.$_dbCallAppConfig.put({
									_id: "node/welcomeMessageConfig",
									id: "welcomeMessageConfig",
									shouldShow: true
								}));
					})
					.then(() => {
						return this.$_dbCallAppConfig.get("node/darkModeSettings")
							.then(
								value => { },
								() => this.$_dbCallAppConfig.put({
									_id: "node/darkModeSettings",
									id: "darkModeSettings",
									darkMode: true
								}));
					})
					.then(() => {
						return this.$_dbCallAppConfig.get("node/emailPrefillConfiguration")
							.then(
								value => { },
								() => this.$_dbCallAppConfig.put({
									_id: "node/emailPrefillConfiguration",
									id: "emailPrefillConfiguration",
									imo: "",
									vesselName: "",
									replyToEmail: "",
									customerName: "",
									sla: ""
								}));
					})
					.catch(e => {
						if (e.status === 409 && e.id === "node/configuration") {
							// Do nothing, this is ok
						} else {
							console.error(e);
						}
					});
			}
		});
	}
};
