var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"items-per-page":5,"dark":"","sort-by":"dateCreated","sort-desc":true,"footer-props":{
			'items-per-page-options':[5, 10, 20, -1],
			'prevIcon': 'fa fa-chevron-left',
			'nextIcon': 'fa fa-chevron-right'
		}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
		var item = ref.item;
return [_c('div',{class:_vm.currentActiveKey(_vm.type) === item._key ? 'active' : 'not-active'})]}},{key:"item.actions",fn:function(ref){
		var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModal(item)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fa fa-upload")])],1)]}}],null,true)},[_vm._v(" Set as active ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.testVersion(item)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v(" fa fa-check ")])],1)]}}],null,true)},[_vm._v(" Test ")])]}},{key:"item.dateCreated",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateCreated))+" ")]}}])}),(_vm.confirmationModalOpen)?_c('confirmation-modal',{attrs:{"item":_vm.selectedItem,"text":_vm.promptText},on:{"setActive":_vm.setAsActive,"cancel":_vm.cancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }