<script>
import Node from "./call-history/Node.vue";
import addToCallHistory from "../../mixins/add-to-call-history";
export default {
	components: {
		Node
	},
	mixins: [addToCallHistory],
	data() {
		return {
			callHistory: []
		};
	},
	computed: {
		showHistory() {
			return this.callHistory.length > 0;
		}
	},
	created() {
		this.fetchCallHistory();
	},
	methods: {
		call(entry) {
			const completeNumber = entry.number + entry.waitSymbol + entry.extension.join("");
			return this.addToCallHistory(entry, completeNumber)
				.then(() => {
					cordova.plugins.phonedialer.call(completeNumber);
				})
				.catch(e => {
					console.log(e);
				});
		},
		fetchCallHistory() {
			return this.$_dbCallHistory.allDocs({ include_docs: true })
				.then(result => {
					this.callHistory = result.rows.filter(entry => !entry.id.startsWith("_design"))
						.map(entry => entry.doc)
						.sort((a, b) => {
							return b.dateCreated - a.dateCreated;
						});
				});
		}
	}
};
</script>

<template>
	<v-container class="pt-0 px-0">
		<v-col class="px-1 py-1 mt-0">
			<div v-if="showHistory">
				<p
					class="headline my-2 whiteBlurText textShadow"
					align="center"
					justify="center"
				>
					Call History
				</p>
				<node
					v-for="entry in callHistory"
					:key="entry.id"
					:node="entry"
					@call="call"
				/>
			</div>
			<div
				v-else
				align="center"
				justify="center"
				class="mt-5 pt-5 whiteBlurText textShadow"
			>
				<p class="subtitle-1">No prior calls made</p>
			</div>
		</v-col>
	</v-container>
</template>

<style lang="css" scoped>
.textShadow {
	text-shadow: 1px 1px 1px #000000 !important;
}
</style>
