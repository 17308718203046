import { request } from "../libs";
import store from '../../store';
const timeout = 8000;

export default function validateNonce({ userId, id, nonce }) {
	return request(
		store.getters["env/serverUrl"] + "/nonce-validation",
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ userId, id, nonce })
		},
		timeout
	)
		.then(res => res.json());
}
