<script>
import { mapGetters } from "vuex";
import extensions from "../../../resources/extensions.json";
import ExtensionSelector from "./call-select/Extension-Selector.vue";
export default {
	components: {
		ExtensionSelector
	},
	data: () => ({
		defaultExtensions: extensions
	}),
	computed: {
		...mapGetters("parsedExcel", ["localExtensions", "activeExtension", "localExistingExtension"]),
		formattedLocalExtensions() {
			return this.localExtensions ? this.localExtensions.data : null;
		},
		extensions() {
			return this.activeExtension || this.formattedLocalExtensions || this.localExistingExtension || {};
		},
	}
};
</script>

<template>
	<v-container class="pt-0 px-0">
		<extension-selector :extensions="extensions" />
	</v-container>
</template>
