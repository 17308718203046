export const UPDATE_LOCAL_EXTENSIONS = "UPDATE_LOCAL_EXTENSIONS";
export const UPDATE_LOCAL_SYNONYMS = "UPDATE_LOCAL_SYNONYMS";

export const UPDATE_EXTENSIONS = "UPDATE_EXTENSIONS";
export const UPDATE_SYNONYMS = "UPDATE_SYNONYMS";
export const UPDATE_ACTIVE_KEY = "UPDATE_ACTIVE_KEY";

export const SET_USER_LOGGED_OUT = "SET_USER_LOGGED_OUT";
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USERNAME = "SET_USERNAME";
export const SET_VALID_SESSION = "SET_VALID_SESSION";
export const SET_INVALID_SESSION = "SET_INVALID_SESSION";
export const UPDATE_LOCAL_EXISTING_EXTENSIONS = "UPDATE_LOCAL_EXISTING_EXTENSIONS";
export const UPDATE_LOCAL_EXISTING_SYNONYMS = "UPDATE_LOCAL_EXISTING_SYNONYMS";
export const UPDATE_ACTIVE = "UPDATE_ACTIVE";
export const CLEAR_LOCAL = "CLEAR_LOCAL";
