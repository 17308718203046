import Vue from "vue";
import Router from "vue-router";
import resetPassword from "../components/views/Reset-Password.vue";
import createUpdatePassword from "../components/views/Create-Update-Password.vue";
import routeResolver from "./Route-Resolver.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			name: "routeResolver",
			component: routeResolver,
		},
		{
			path: "/reset-password",
			name: "resetPassword",
			component: resetPassword,
		},
		{
			path: "/password-reset",
			name: "createUpdatePassword",
			component: createUpdatePassword,
		},
		{
			path: "/:notFound(.*)",
			redirect: { name: "login" }
		},
	]
});

export default router;
