<script>
import { mapActions } from "vuex";

export default {
	methods: {
		...mapActions("session", ["logout"]),
	},
};
</script>

<template>
	<div>
		<v-app-bar dark>
			<div>
				<img
					src="../../../../resources/kongsberg_horizontal_light.png"
					width="220px"
					height="40px"
				/>
			</div>
			<v-spacer/>
			<div>
				<v-toolbar-title>KM-support Dashboard</v-toolbar-title>
			</div>
			<v-spacer/>
			<v-btn
				elevation="0"
				@click="logout"
			>
				Logout
			</v-btn>
		</v-app-bar>
	</div>
</template>

<style scoped>
</style>