<script>
import { requestPasswordReset } from "../../api";
export default {
	data() {
		return {
			username: "",
			email: "",
			forgotUsername: false,
			forgotEmail: true,
			errorMessage: "",
			successMessage: ""
		};
	},
	methods: {
		sendResetPassword() {
			if (this.forgotEmail && !this.username) {
				return this.errorMessage = "* Username is empty";
			} else if (this.forgotUsername && !this.email) {
				return this.errorMessage = "* Email is empty";
			}
			return requestPasswordReset({ user: this.username, email: this.email })
				.then(() => {
					this.successMessage = "If the user is registered in the system, a password reset email will be sent.";
				})
				.catch(() => {
					this.errorMessage = "An error occured while trying to request password-reset.";
				});
		},
		setForgotUsername() {
			this.resetValues();
			this.forgotUsername = true;
			this.forgotEmail = false;
		},
		setforgotEmail() {
			this.resetValues();
			this.forgotUsername = false;
			this.forgotEmail = true;
		},
		resetValues() {
			this.errorMessage = "";
			this.username = "",
			this.email = "";
		}
	}
};
</script>

<template>
	<v-app id="inspire">
		<v-container
			fluid fill-height
			style="width:1800px"
		>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md5>
					<v-card class="elevation-12 px-4 py-4">
						<div class="pb-4">
							<img
								src="../../../../resources/kongsberg_horizontal_light.png"
								width="50%"
							/>
						</div>
							<v-form @submit.prevent="sendResetPassword">
								<v-text-field
									v-show="forgotEmail"
									v-model="username"
									label="Username"
									prepend-inner-icon="fa fa-user"
									outlined
									type="text"
								/>
								<v-text-field
									v-show="forgotUsername"
									v-model="email"
									label="Email"
									prepend-inner-icon="fa fa-envelope"
									outlined
									type="text"
								/>
								<a
									v-if="!forgotUsername && forgotEmail"
									@click="setForgotUsername"
								>
									I forgot my username
								</a>
								<a
									v-else
									@click="setforgotEmail"
								>
									I forgot my email
								</a>
								<v-btn
									block
									large
									class="my-1"
									type ="submit"
									color="primary"
								>
								Reset Password</v-btn>
								<div
									v-if="this.errorMessage"
									class="warningMessage pt-2"
									color="warning"
								>
									{{ errorMessage }}
								</div>
								<div
									v-else-if="this.successMessage"
									class="successMessage pt-2"
								>
									{{ successMessage }}
								</div>
							</v-form>
							<router-link
								:to="{ name: 'routeResolver' }"
								color="primary"
							>
								Back to login
							</router-link>
						</div>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<style lang="scss" scoped>
	.warningMessage {
		color: red;
		padding: 5px;
	}
</style>