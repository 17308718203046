<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import { mdiPhone, mdiMenuRight, mdiEmail } from "@mdi/js";
import extensionsBgColor from "../../../mixins/extensions-bg-color";
import addToCallHistory from "../../../mixins/add-to-call-history";
import callConfig from "../../../../resources/callConfig.json";
export default {
	mixins: [extensionsBgColor, addToCallHistory],
	props: {
		maxDepthReached: {
			type: Boolean
		}
	},
	data: () => ({
		icons: {
			mdiPhone,
			mdiMenuRight,
			mdiEmail
		},
		callConfig
	}),
	computed: {
		...mapGetters("extensionsSelector", ["selectedExtensions", "currentDepth"]),
		...mapGetters("parsedExcel", ["localExtensions","activeExtension", "localExistingExtension"]),
		formattedLocalExtensions() {
			return this.localExtensions ? this.localExtensions.data : null;
		},
		extensions() {
			return this.activeExtension || this.formattedLocalExtensions || this.localExistingExtension || {};
		},
		nodes() {
			const nodes = [];
			this.selectedExtensions.reduce((acc, extensionNumber, index) => {
				acc += extensionNumber;
				const foundExtension = this.findExtension(this.extensions[index + 1], acc);
				nodes.push(foundExtension);
				return acc;
			}, "");
			return nodes;
		},
		lastNode() {
			return this.nodes[this.nodes.length - 1];
		},
		descriptions() {
			return this.nodes.map(node => node.description);
		},
		extensionUrl() {
			const path = this.selectedExtensions.join("");
			return this.findExtension(this.extensions[this.selectedExtensions.length], path).url;
		},
		extensionText() {
			return this.selectedExtensions.join(".");
		},
		numberWithExtensions() {
			return this.callConfig.phoneNumber.number + this.callConfig.waitSymbol + this.selectedExtensions.join("");
		},
		rootExtension() {
			return this.selectedExtensions[0];
		},
		contactOptions() {
			if (this.lastNode.email && this.lastNode.callEnabled) {
				return "The app is now ready to call/mail directly to the following KM-support team:";
			} else if (this.lastNode.email && !this.lastNode.callEnabled) {
				return "The app is now ready to mail directly to the following KM-support team:";
			} else if (this.lastNode.callEnabled && !this.lastNode.email) {
				return "The app is now ready to call directly to the following KM-support team:";
			}
			return this.lastNode.url.length ? "Click the link below to access our webpage regarding:" : "";
		},
		extensionBackgroundColor() {
			return this.getBackgroundColor(this.rootExtension);
		}
	},
	methods: {
		call() {
			return this.addToCallHistory({
				extension: this.selectedExtensions,
				number: this.callConfig.phoneNumber.number,
				waitSymbol: this.callConfig.waitSymbol,
				descriptions: this.descriptions
			}, this.numberWithExtensions)
				.then(() => {
					cordova.plugins.phonedialer.call(this.numberWithExtensions);
				})
				.catch(e => {
					console.log(e);
				});
		},
		openMailService() {
			return this.$_dbCallAppConfig.get("node/emailPrefillConfiguration")
				.then(prefillConfig => {
					const today = new Date();
					const date = Intl.DateTimeFormat("fr-ca", { year: "2-digit", month: "2-digit", day: "2-digit" }).format(today).replace(/-/g, "");
					const time = Intl.DateTimeFormat("en", { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }).format(today).replace(/:/g, "");
					const mailId = `ID-${this.extensionText}/${date + time}`;
					const prefillData = `IMO: ${prefillConfig.imo || ""} %0d%0a Vessel name: ${prefillConfig.vesselName || ""} %0d%0a Reply-to-email: ${prefillConfig.replyToEmail || ""} %0d%0a Customer name: ${prefillConfig.customerName || ""} %0d%0a SLA: ${prefillConfig.sla || ""}`;
					const locationString = `mailto:${this.lastNode.email}?subject=${mailId} >&body=[REF:## ${mailId} ##DO NOT REMOVE] %0d%0a ${prefillData} %0d ${this.lastNode.emailBody || ""}`;
					window.location.href = locationString;
				});
		},
		findExtension(extensions, path) {
			return extensions.find(extension => {
				return extension.extensionPath.join("") === path;
			});
		}
	}
};
</script>

<template>
	<div
		v-if="maxDepthReached"
		class="mt-0 pt-3 px-1"
	>
		<v-card
			class="px-4 py-5"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
		>
			<h1
				style="font-size: 14px;"
				class="mt-0 mb-3 centerAlign"
			>
				{{ contactOptions }}
			</h1>
			<v-divider />
			<v-container class="centerAlign kmCallPathDisplay py-0">
				<v-row>
					<v-col
						cols="1"
						class="kmPathColorBand py-0 pl-0"
						:style="{ backgroundColor: extensionBackgroundColor }"
					/>
					<v-col cols="11">
						<p
							class="mb-0 black--text negativeLeftPadding"
							style="font-size: 15px;"
						>
							{{ extensionText }}
						</p>
						<span
							v-for="(description, index) in descriptions"
							:key="index"
							style="font-size: 13px;"
							class="black--text"
						>
							{{ description }}
							<v-icon
								v-if="index + 1 !== descriptions.length"
								color="black"
							>
								{{ icons.mdiMenuRight }}
							</v-icon>
						</span>
					</v-col>
				</v-row>
			</v-container>
			<v-divider />
			<div class="centerAlign">
				<v-btn
					v-if="lastNode.callEnabled"
					color="green"
					class="mt-3 kmCallButton"
					large
					@click="call"
				>
					<v-icon
						color="white"
						size="30"
					>
						{{ icons.mdiPhone }}
					</v-icon>
				</v-btn>
				<v-btn
					v-if="lastNode.email"
					color="blue"
					:class="lastNode.callEnabled ? 'ml-5 mt-3 kmCallButton' : 'mt-3 kmCallButton'"
					large
					@click="openMailService"
				>
					<v-icon
						color="white"
						size="30"
					>
						{{ icons.mdiEmail }}
					</v-icon>
				</v-btn>
			</div>
			<p
				v-if="extensionUrl"
				style="font-size: 13px"
				class="mt-4 mb-0 centerAlign"
			>
				{{ `${(lastNode.callEnabled || lastNode.email) && lastNode.url.length ? "Or visit:": ""}` }} <a :href="extensionUrl">{{ extensionUrl }}</a>
			</p>
		</v-card>
	</div>
</template>

<style lang="css" scoped="">
.centerAlign {
	text-align: center;
}
.finalDescription {
	font-size: 16px;
}
.kmCallPathDisplay {
	border-radius: 4px 4px 4px 4px;
	background-color: rgb(245, 245, 245);
}
.kmPathColorBand {
	border-radius: 4px 0px 0px 4px;
	background-clip: content-box;
}
.kmCallButton {
	height: 3.5em !important;
	width: 7em !important;
}
.negativeLeftPadding {
	margin-left: -27px !important;
}
</style>
