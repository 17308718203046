<script>
import Information from "./about/Information.vue";
import PowerdByZegeba from "../../../resources/Zegeba_Color_Poweredby.png";
export default {
	components: {
		Information
	},
	data: () => ({
		PowerdByZegeba
	})
};
</script>

<template>
	<v-container class="pt-0 px-0">
		<v-col class="px-1 py-1 mt-2 mb-5 pb-5">
			<information />
		</v-col>
	</v-container>
</template>

<style lang="css" scoped="">
.img-wrapper {
  height: 30px;
  margin: 1%;
}
.img-wrapper img{
  height: 30px;
  object-fit: cover;
}
</style>
