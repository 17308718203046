export default function(data) {
	let lastFoundNode;
	const extensionsToPush = {};
	Object.keys(data).forEach(key => {
		if (key === "Phone icon Active") {
			if (lastFoundNode && data[key] !== '') {
				extensionsToPush[lastFoundNode].callEnabled = data[key];
			}
		} else if (key === "Support Team") {
			if (lastFoundNode && data[key] !== '') {
				extensionsToPush[lastFoundNode].email = data[key];
			}
		} else if (key === "Pre-formatted text for email") {
			if (lastFoundNode && data[key] !== '') {
				data[key] = `%0d%0a%0d%0a${data[key].replace(/\n/g, "%0d%0a")}`;
				extensionsToPush[lastFoundNode].emailBody = data[key];
			}
		} else {
			if (data[key] !== "" && data[key].charAt(0) !== "*") {
				lastFoundNode = key;
				const extensionPath = data[key].slice(0, data[key].indexOf(":")).split(".");
				extensionPath.forEach(path => {
					if (path.length > 1) {
						console.log(key);
						console.log(extensionPath);
						throw {
							code: 1,
							message: `Found an invalid path ${JSON.stringify(extensionPath)}`
						}
					}
				})
				const splitDescription = data[key].slice(data[key].indexOf(":") + 1).split("|");
				const lengthOfPath = extensionPath.length;
				const depth = lengthOfPath;
				const description = splitDescription[0].trim();
				const url = (splitDescription[1] || "").trim();
				extensionsToPush[key] = {
					extensionPath,
					description,
					depth,
					url
				};
			}
		}
	});
	return extensionsToPush;
}
