import { request } from "../libs";

export default (serverUrl, extension) => {
	return request(
		`${serverUrl}/phone-menu`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: extension
		}
	)
		.then(response => response.json());
};
