import Vue from "vue";
import AppWrapper from "./App-Wrapper.vue";
import vuetify from "./plugins/vuetify";
import theme from "./mixins/theme";
import store from "./store";
import router from "./src-dashboard/router/index";
import * as filters from "./src-dashboard/filters";

Vue.mixin(theme);
Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});

new Vue({
	vuetify,
	store,
	router,
	render: h => h(AppWrapper)
}).$mount("#app");
