<script>
import { version } from "../../../../package.json";
// import callConfig from "../../../../resources/callConfig.json";
export default {
	data: () => ({
		version
		// callConfig
	}),
	computed: {
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		}
	}
};
</script>

<template>
	<div
		class="mt-0"
	>
		<v-card
			class="mx-auto mb-2"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : 'whiteBoxStyling'"
		>
			<v-card-text>
				<p
					class="headline mb-0"
					align="center"
					justify="center"
					:style="{ color: textColor }"
				>
					About the app
				</p>
				<v-divider class="mb-3 mt-2" />
				<p
					style="font-size: 14px;"
					:style="{ color: textColor }"
					class="mb-0"
					align="center"
					justify="center"
				>
					This app has been created to make it easy for you to call/mail directly to the correct Kongsberg Maritime support team. If you need to get in touch with other divisions of the Kongsberg Group, please refer to our corporate website,
					<a href="https://www.kongsberg.com/">
						www.kongsberg.com
					</a>
				</p>
				<v-divider class="mb-3 mt-2" />
				<p
					style="font-size: 14px;"
					:style="{ color: textColor }"
					class="mb-0"
					align="center"
					justify="center"
				>
					In case you do not find the menu-option for the support you require, you can select <strong>6.3 Front-End Coordinator</strong> for assistance or dial us directly: Kongsberg Maritime 24/7/365 Global Customer Support at <strong>+47 3303 2407</strong>.
				</p>
				<p
					style="font-size: 14px;"
					:style="{ color: textColor }"
					class="mb-0"
					align="center"
					justify="center"
				>
					Email:
					<a
						href="mailto:km.support@kongsberg.com"
						class="emailListItem"
					>
						km.support@kongsberg.com
					</a><br><br>
					We appreciate your feedback at km.support@kongsberg.com<br><br>Thank you!
				</p>
				<v-divider class="mb-3 mt-2" />
				<p
					style="font-size: 14px;"
					:style="{ color: textColor }"
					class="mb-0"
					align="center"
					justify="center"
				>
					For assistance on how to set up and manage your email-account on your mobile phone, contact your System Administrator or mobile phone provider.
				</p>
				<v-divider class="mb-3 mt-2" />
				<p
					style="font-size: 14px;"
					:style="{ color: textColor }"
					class="mb-0 mt-5"
					align="center"
					justify="center"
				>
					Running version {{ version }}
				</p>
			</v-card-text>
		</v-card>
	</div>
</template>

<style lang="css">
.whiteBoxStyling {
	background-color: rgba(255, 255, 255, 0.96) !important;
}
.emailListItem {
	text-decoration: none;
}
</style>
