import { request } from "../libs";

export default (serverUrl) => {
	return request(
		`${serverUrl}/phone-menu`,
		{
			method: "GET"
		}
	)
		.then(response => response.json());
};
