import Vue from "vue";
import Vuex from "vuex";
import extensionsSelector from "./modules/extensions-selector";
import welcomePrompt from "./modules/welcome-prompt";
import dataHandler from "../src-dashboard/store-extension/modules/data-handler";
import parsedExcel from "../src-dashboard/store-extension/modules/parsed-excel";
import request from "../src-dashboard/store-extension/modules/request";
import session from "../src-dashboard/store-extension/modules/session";
import env from "../src-dashboard/store-extension/modules/env";
import reloadPage from "./plugins/reload-page";
// import historyCleanUp from "./plugins/history-clean-up";

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== "production",
	modules: {
		extensionsSelector,
		welcomePrompt,
		parsedExcel,
		request,
		dataHandler,
		session,
		env
	},
	plugins: [
		reloadPage
	]
});
