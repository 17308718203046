import { request } from "../libs";

export default (serverUrl, contentType) => {
	return request(
		`${serverUrl}/content/${contentType}`,
		{
			method: "GET"
		}
	)
		.then(response => response.json());
};
