<script>
import login from "../components/views/Login.vue";
import home from "../components/views/Home.vue";
import store from "@/store";
const components = {
	login,
	home
}
export default {
	functional: true,
	render(createElement, ctx) {
		const componentToRender = store.getters["session/validSession"] ? components.home : components.login;
		return createElement(componentToRender, ctx.data, ctx.children)
  }
}
</script>
