<script>
import EventBus from "../../../mixins/event-bus";
import { mdiMagnify, mdiClose } from "@mdi/js";
import debounce from "lodash/debounce";
export default {
	data: () => ({
		searchText: "",
		icons: {
			mdiMagnify,
			mdiClose
		}
	}),
	watch: {
		searchText(newValue) {
			if (newValue.length > 1) {
				this.debounceSearch();
			} else {
				return this.$emit("search", "");
			}
		}
	},
	mounted() {
		EventBus.$on("clearSearch", () => {
			this.searchText = "";
		});
	},
	beforeDestroy() {
		EventBus.$off("clearSearch");
	},
	methods: {
		debounceSearch: debounce(function() {
			this.$emit("search", this.searchText);
		}, 400)
	}
};
</script>

<template>
	<v-text-field
		v-model="searchText"
		solo
		hide-details
		label="Search product / support team"
	>
		<template v-slot:append>
			<div>
				<v-icon v-if="searchText === ''">
					{{ icons.mdiMagnify }}
				</v-icon>
				<v-btn
					v-else
					icon
					small
					@click="searchText = ''"
				>
					<v-icon>
						{{ icons.mdiClose }}
					</v-icon>
				</v-btn>
			</div>
		</template>
	</v-text-field>
</template>
