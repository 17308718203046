<script>
import { mdiEmail, mdiContentSave } from "@mdi/js";
import utils from "@/utils";
export default {
	$dbQuery: {
		emailPrefillConfiguration() {
			return {
				default: {},
				selector: {
					_id: "node/emailPrefillConfiguration"
				},
				limit: 1,
				db: this.$_dbCallAppConfig.name,
				onLoad() {
					this.emailConfig = JSON.parse(JSON.stringify(this.emailPrefillConfiguration));
				}
			};
		}
	},
	data() {
		return {
			showSnackBar: false,
			icons: {
				mdiEmail,
				mdiContentSave
			},
			emailConfig: {},
			emailRule: [
				v => (!v || !!utils.isEmailAddress(v)) || "Email is not valid"
			]
		};
	},
	computed: {
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].selectColor;
		},
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		},
		themeIconColor() {
			return this.$_theme === "dark" ? "white" : this.btnColor;
		},
		isValid() {
			if (this.emailConfig.replyToEmail && this.emailConfig.replyToEmail.length) {
				return utils.isEmailAddress(this.emailConfig.replyToEmail);
			}
			return true;
		}
	},
	methods: {
		saveConfiguration() {
			this.emailPrefillConfiguration = this.emailConfig;
			this.showSnackBar = true;
		}
	}
};
</script>

<template>
	<div>
		<v-card
			class="mt-4 mx-1 py-2"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
		>
			<v-snackbar
				v-model="showSnackBar"
				centered
				:timeout="2000"
			>
				Configuration Saved
			</v-snackbar>
			<v-card-actions>
				<p class="subtitle-1 mb-0 ml-1">
					Select email prefill configuration&nbsp;
				</p>
				<v-icon
					:color="themeIconColor"
					size="20"
				>
					{{ icons.mdiEmail }}
				</v-icon>
			</v-card-actions>
			<v-card-text class="mt-0 pt-0">
				<p
					style="font-size: 14px;"
					align="center"
					justify="center"
				>
					The fields below are optional, and the information will be pre-filled in emails that you send to Kongsberg Maritime Support from the app.
				</p>
				<v-text-field
					v-model="emailConfig.imo"
					outlined
					class="pb-2"
					hide-details
					label="IMO"
				/>
				<v-text-field
					v-model="emailConfig.vesselName"
					outlined
					class="pb-2"
					hide-details
					label="Vessel Name"
				/>
				<v-text-field
					v-model="emailConfig.replyToEmail"
					outlined
					:rules="emailRule"
					hide-details
					class="pb-2"
					label="Reply-to-email"
				/>
				<v-text-field
					v-model="emailConfig.customerName"
					outlined
					class="pb-2"
					hide-details
					label="Customer Name"
				/>
				<v-text-field
					v-model="emailConfig.sla"
					outlined
					class="pb-2"
					hide-details
					label="SLA"
				/>
				<v-btn
					medium
					block
					:disabled="!isValid"
					@click="saveConfiguration"
				>
					save
				</v-btn>
			</v-card-text>
		</v-card>
	</div>
</template>

<style lang="css" scoped>
.textShadow {
	text-shadow: 1px 1px 1px #000000 !important;
}
</style>
