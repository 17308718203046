import zegebaFetch from "@zegeba/zegeba-fetch";

export default function request(url, options, timeout = 60000) {
	const accessToken = localStorage.getItem("access_token");

	if (accessToken) {
		options.headers = {
			...options.headers,
			"Authorization": accessToken
		};
	}
	return zegebaFetch(url, options, timeout);
}
