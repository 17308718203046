import { request } from "../libs";

export default (serverUrl, args) => {
	return request(
		`${serverUrl}/select-content?contentKey=${args.contentKey}&id=${args.documentHandle}`,
		{
			method: "POST"
		}
	);
};
