<script>
import SearchResultEntry from "./Search-Result-Entry.vue";
import EventBus from "../../../../mixins/event-bus";
import extensionsBgColor from "../../../../mixins/extensions-bg-color";
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		SearchResultEntry
	},
	mixins: [extensionsBgColor],
	props: {
		searchedExtensions: {
			type: Object,
			default: () => {}
		},
		searchText: {
			type: String,
			default: () => ""
		}
	},
	computed: {
		...mapGetters("extensionsSelector", ["currentDepth"]),
		extensionResults() {
			return this.searchedExtensions.extension;
		},
		synonymResults() {
			return this.searchedExtensions.synonym;
		},
		sortedExtensionSearchResult() {
			const splitExtensionSearchResult = this.splitSearchResult(this.extensionResults);
			return [...Object.values(splitExtensionSearchResult.exactMatches).flat(), ...splitExtensionSearchResult.fuzzyMatches];
		},
		sortedSynonymSearchResult() {
			const splitSynonymSearchResult = this.splitSearchResult(this.synonymResults, { alwaysCompartmentalize: true });
			return [...Object.values(splitSynonymSearchResult.exactMatches).flat(), ...splitSynonymSearchResult.fuzzyMatches];
		},
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].menuRightButton;
		},
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		}
	},
	methods: {
		...mapActions("extensionsSelector", ["updateExtension", "updateDepth", "resetSelections"]),
		splitSearchResult(results, options) {
			return results.reduce((acc, entry) => {
				const itemContainsSearchTerm = entry.description.toLowerCase().indexOf(this.searchText.toLowerCase());
				if (itemContainsSearchTerm !== -1 || options.alwaysCompartmentalize) {
					if (acc.exactMatches[entry.extensionPath[0]]) {
						const positionToInsert = this.getPositionToInsert(acc.exactMatches[entry.extensionPath[0]], entry);
						acc.exactMatches[entry.extensionPath[0]].splice(positionToInsert, 0, entry);
					} else {
						acc.exactMatches[entry.extensionPath[0]] = [entry];
					}
				} else {
					acc.fuzzyMatches.push(entry);
				}
				return acc;
			}, {
				exactMatches: {},
				fuzzyMatches: []
			});
		},
		getPositionToInsert(list, entry) {
			const pathA = entry.extensionPath;
			let position = list.length;
			for (let i = 0; i < list.length; i++) {
				const pathB = list[i].extensionPath;
				const overtakePosition = this.shouldOvertake(pathA, pathB);
				if (overtakePosition) {
					position = i;
					break;
				}
			};
			return position;
		},
		shouldOvertake(pathA, pathB) {
			let overtakePosition = false;
			for (let i = 0; i < pathA.length; i++) {
				if (pathA[i] > pathB[i]) {
					break;
				} else if (pathA[i] < pathB[i]) {
					overtakePosition = true;
					break;
				} else if (i + 1 === pathA.length && pathA.length < pathB.length) {
					overtakePosition = true;
					break;
				}
			}
			return overtakePosition;
		},
		selectExtension(extension) {
			setTimeout(() => {
				this.resetSelections()
					.then(() => this.updateExtension(extension.extensionPath))
					.then(() => this.updateDepth(extension.depth + 1))
					.then(EventBus.$emit("clearSearch"));
			}, 150);
		},
		decideBackgroundColor(rootExtension) {
			return this.getBackgroundColor(rootExtension);
		}
	}
};
</script>

<template>
	<div>
		<div
			v-if="sortedExtensionSearchResult.length || sortedSynonymSearchResult.length"
			:class="{extraBottomSpacing: currentDepth > 1}"
		>
			<div v-if="sortedExtensionSearchResult.length">
				<p class="my-0 whiteBlurText">
					Search result:
				</p>
				<search-result-entry
					v-for="extension in sortedExtensionSearchResult"
					:key="extension.extensionPath.join()"
					:extension="extension"
					:background-color="decideBackgroundColor(extension.extensionPath[0])"
					:text-color="textColor"
					:search-text="searchText"
					:btn-color="btnColor"
					@selectExtension="selectExtension"
				/>
			</div>
			<div v-if="sortedSynonymSearchResult.length">
				<p class="my-0 whiteBlurText">
					Other relevant extensions:
				</p>
				<search-result-entry
					v-for="extension in sortedSynonymSearchResult"
					:key="extension.extensionPath.join()"
					:extension="extension"
					:background-color="decideBackgroundColor(extension.extensionPath[0])"
					:text-color="textColor"
					:search-text="searchText"
					:btn-color="btnColor"
					@selectExtension="selectExtension"
				/>
			</div>
		</div>
		<p
			v-else
			class="my-0 whiteBlurText"
		>
			Your search had no matches
		</p>
	</div>
</template>

<style lang="css" scoped>
.extraBottomSpacing {
	margin-bottom: 60px;
}
</style>
