export default function sortSearchResults(array, searchString) {
	const escapedSearchString = escapeRegExp(searchString);
	const regexPatterns = getRegexPatterns(escapedSearchString);
	const sortRankMap = getSortRanks(array, regexPatterns, searchString);
	return array.sort((a, b) => {
		return sortRankMap[a] - sortRankMap[b];
	});
}

function getRegexPatterns(searchString) {
	const fullWordStartOfString = `^${searchString}\\b`;
	const fullWordInsideString = `(?!^)\\s${searchString}([^-\\w]|$)`;
	const startOfWordStartOfString = `^${searchString}(?=\\w+?)`;
	const startOfWordInsideStringOrPrecedentedByHyphen = `(?!^)[-\\s]${searchString}(?=\\w+?)|-${searchString}`;
	return [fullWordStartOfString, fullWordInsideString, startOfWordStartOfString, startOfWordInsideStringOrPrecedentedByHyphen, searchString]
		.map(regexString => new RegExp(regexString, "i"));
}

function escapeRegExp(string) {
	return string.replace(/[.*+\-?ˆ${}()|[\]\\]/g, "\\$&");
}

function getSortRank(string, regexPatterns, searchString) {
	if (!string.toLowerCase().includes(searchString.toLowerCase())) {
		return regexPatterns.length;
	}
	for (let i = 0; i < regexPatterns.length; i++) {
		const regex = regexPatterns[i];
		if (regex.test(string)) {
			return i;
		}
	}
	return regexPatterns.length;
}

function getSortRanks(array, regexPatterns, searchString) {
	return array.reduce((acc, string) => {
		acc[string] = getSortRank(string, regexPatterns, searchString);
		return acc;
	}, {});
}
