
<script>
import Vue from "vue";
import App from "../../../App.vue";
import vuetify from "../../../plugins/vuetify";
import store from "../../../store";
import vueStorage from "@zegeba/vue-storage";
import dbSetup from "../../../plugins/db-setup";
import theme from "../../../mixins/theme";
import { phoneMenuKey, searchListKey } from "@dashboard/utils/app-state-keys";
import router from "../../../router";
import { mapActions } from "vuex";
import KmSupportDashboard from "../../Km-Support-Dashboard.vue";
export default {
	components: {
		KmSupportDashboard
	},
	methods: {
		...mapActions("parsedExcel", ["getActiveContent"]),
		mountCallApp() {
			Vue.use(vueStorage);
			Vue.use(dbSetup);
			Vue.mixin(theme);
			Vue.config.productionTip = false;
			new Vue({
				vuetify,
				store,
				router,
				render: h => h(App)
			}).$mount("#mobile-app-container");
		},
		fetchInitialData() {
			return this.getActiveContent(phoneMenuKey)
				.then(
					() => this.getActiveContent(searchListKey),
					() => this.getActiveContent(searchListKey)
				)
				.then(
					() => this.mountCallApp(),
					() => this.mountCallApp()
				);
		}
	},
	mounted() {
		this.fetchInitialData()
	}
};
</script>

	<template>
		<v-row>
			<v-col cols="6">
				<div class="km-support-dashboard mx-5 mt-5 pt-5">
					<km-support-dashboard />
				</div>
			</v-col>
			<v-col cols="6">
				<div class="mobile-app-container mr-5">
					<div id="mobile-app-container"/>
				</div>
			</v-col>
		</v-row>
	</template>

<style>

</style>