import { request } from "../libs";
import store from '../../store';
const timeout = 3000;

function login({ username, password }) {
	return request(
		`${store.getters["env/serverUrl"]}/auth`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ user: username, pass: password })
		},
		timeout
	)
		.then(response => response.json())
		.then(body => {
			localStorage.setItem("access_token", `Bearer ${body.accessToken.token}`);
			localStorage.setItem("refresh_token", body.refreshToken.token);
			return body;
		});
}

export default login;
