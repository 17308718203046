<script>
export default {
	props: {
		item: {
			type: Object
		},
		type: {
			type: String
		},
		text: {
			type: String
		}
	},
	data() {
		return {
			dialog: true
		};
	},
	computed: {
		promptText() {
			return this.text || `Do you want to set this ${ this.item.type || type } as active?`;
		}
	},
	methods: {
		setActive() {
			this.$emit("setActive", this.item || this.type);
		},
		cancel() {
			this.$emit("cancel", this.item || this.type);
		}
	}
};
</script>

<template lang="html">
	<v-dialog
		v-model="dialog"
		persistent
		width="450"
	>
		<v-card>
			<v-system-bar class="add-bar">
				<v-card-title>
					<span class="headline white--text">
						Set as active
					</span>
				</v-card-title>
			</v-system-bar>
			<v-card>
				<v-card-text class="subheading">
					{{ promptText }}
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						color=""
						@click="cancel"
					>
						CANCEL
					</v-btn>
					<v-btn
						color="green"
						@click="setActive"
					>
						SET AS ACTIVE
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-card>
	</v-dialog>
</template>

<style lang="css" scoped>
.add-bar {
	height: 40px !important;
	color: rgb(60, 60, 60) !important;
	background-color: rgb(60, 60, 60) !important;
	border-bottom: solid lightgrey 0.5px;
}
.add-bar span {
	font-size: 18px !important;
}
</style>
