import { v4 as uuidv4 } from "uuid";
export default {
	methods: {
		addToCallHistory(entry, completeNumber) {
			const id = uuidv4();
			return this.$_dbCallHistory.put({
				_id: `node/${id}`,
				id: id,
				dateCreated: new Date().getTime(),
				extension: entry.extension,
				number: entry.number,
				waitSymbol: entry.waitSymbol,
				completeNumber: completeNumber,
				type: "callHistory",
				descriptions: entry.descriptions
			});
		}
	}
};
