<script>
import EventBus from "../../utils/EventBus";
import csv from "csv-parser";
import fileReaderStream from "filereader-stream";
import concatStream from "concat-stream";
import bufferToStream from "buffer-to-stream";
import ensureEncoding from "../../utils/ensure-encoding";
import parseExtensionExcelV2 from "../../utils/parse-extension-excel-version-2";
import { mapActions } from "vuex";
import { mdiFileUploadOutline } from "@mdi/js";
import VueFilePicker from "../elements/Vue-File-Picker.vue";

const hashMapMap = {
  "Front End": 1,
  "Back-End (Level 2)": 2,
  "Back-End (Level 3)": 3,
  "Back-End (Level 4)": 4
};

export default {
	components: {
		VueFilePicker
	},
	data() {
		return {
			selectedFile: {},
			icons: {
				mdiFileUploadOutline
			}
		}
	},
	methods: {
		...mapActions("parsedExcel", ["updateLocalExtensions", "clearLocal"]),
		attachmentSelected(files) {
			const selectedFile = files[0]
			this.selectedFile = selectedFile;
			let results = {
				1: [],
				2: [],
				3: [],
				4: []
			};

			const parseData = concatStream(fileBuffer => parseBuffer(fileBuffer));

			fileReaderStream(selectedFile)
				.pipe(parseData);

			const vm = this;
			function parseBuffer(fileBuffer) {
				const data = ensureEncoding(fileBuffer, "utf8");

				bufferToStream(data)
					.pipe(csv({ separator: ";" }))
					.on("data", data => {
						try {
							const extensionsToPush = parseExtensionExcelV2(data);
							Object.keys(extensionsToPush).forEach(key => {
								results[hashMapMap[key]].push(extensionsToPush[key]);
							});
						} catch (error) {
							console.log(error);
							let errorMessage = "Unable to parse file, there might be some issues in the uploaded file.";
							if (error.code === 1) {
								errorMessage = error.message;
							}
							alert(errorMessage);
							throw error
						}
					})
					.on("end", () => {
						results["1"] = vm.removeDuplicates(results["1"]);
						results["2"] = vm.removeDuplicates(results["2"]);
						results["3"] = vm.removeDuplicates(results["3"]);
						results["4"] = vm.removeDuplicates(results["4"]);
						vm.updateLocalExtensions({ fileName: selectedFile.name, data: results});
					});
				}
		},
		removeDuplicates(results) {
			return Object.values(results.reduce((acc, entry) => {
				const key = entry.extensionPath.join();
				acc[key] = entry;
				return acc;
			}, {}));
		},
		clearExtensionsFileSelect() {
			this.selectedFile = {};
			return this.clearLocal("Extensions");
		}
	},
	mounted() {
		EventBus.$on("clearExtensionsFileSelect", this.clearExtensionsFileSelect);
	},
	beforeDestroy() {
		EventBus.$off("clearExtensionsFileSelect", this.clearExtensionsFileSelect);
	}
};
</script>

<template>
	<div>
		<vue-file-picker
			id="filePicker"
			inputId="extensionsFilePicker"
			emitMessage="extensionsListSelected"
			v-on:extensionsListSelected="attachmentSelected"
		>
			<template slot="icon">
				<v-icon large>
					{{ icons.mdiFileUploadOutline }}
				</v-icon>
			</template>
			<template slot="file">
				{{ selectedFile.name }}
			</template>
		</vue-file-picker>
	</div>
</template>
