<script>
import { mapGetters, mapActions } from "vuex";
import { phoneMenuKey, searchListKey } from "@dashboard/utils/app-state-keys";
import ConfirmationModal from "./Confirmation-Modal.vue";
export default {
	props: {
		type: {
			type: String,
			required: true
		}
	},
	components: {
		ConfirmationModal
	},
	data() {
		return {
			phoneMenuKey,
			searchListKey,
			selectedItem: {},
			confirmationModalOpen: false
		};
	},
	computed: {
		...mapGetters("dataHandler", ["extensions", "synonyms", "currentActiveKey"]),
		...mapGetters("parsedExcel", ["localExistingExtension", "localExistingSynonym"]),
		promptText() {
			return `Setting these ${this.type === this.phoneMenuKey ? "Extensions" : "Synonyms"} as active will immediatly change what the user see in the app, are you sure?`
		},
		headers() {
			return [
				{ text: "Active", align: "left", value: "active", sortable: false },
				{ text: "Actions", align: "left", value: "actions", sortable: false },
				{ text: "File Name", align: "left", value: "fileName" },
				{ text: "Date added", align: "left", value: "dateCreated" },
				{ text: "Data type", align: "left", value: "type", sortable: false },
				{ text: "Creator", align: "left", value: "creator" },
			];
		},
		items() {
			if (this.type === this.phoneMenuKey) {
				return this.extensions;
			} else if (this.type === this.searchListKey) {
				return this.synonyms;
			}
			return [];
		},
		asd() {
			return this.currentActiveKey(this.type);
		}
	},
	methods: {
		...mapActions("request", ["selectContent", "getExtension", "getSynonym"]),
		...mapActions("parsedExcel", ["updateLocalExistingExtensions", "updateLocalExistingSynonyms", "getActiveContent"]),
		...mapActions("dataHandler", ["updateCurrentActiveKey"]),
		setAsActive() {
			return this.selectContent({
				contentKey: this.selectedItem.type === this.phoneMenuKey ? this.phoneMenuKey : this.searchListKey,
				documentHandle: this.selectedItem._key
			})
				.then(() => this.getActiveContent(this.selectedItem.type))
				.then(() => this.cancel())
				.then(() => this.updateCurrentActiveKey(this.type));
		},
		testVersion(item) {
			if (item.type === this.phoneMenuKey) {
				return this.getExtension(item._key)
					.then(extension => {
						this.updateLocalExistingExtensions(extension.data);
					});
			} else {
				return this.getSynonym(item._key)
				.then(synonym => {
					this.updateLocalExistingSynonyms(synonym.data);
				});
			}
		},
		openModal(item) {
			this.selectedItem = item;
			this.confirmationModalOpen = true;
		},
		cancel() {
			this.confirmationModalOpen = false;
			this.selectedItem = {};
		}
	}
};
</script>
<template>
	<div>
		<v-data-table
			:items="items"
			:headers="headers"
			:items-per-page="5"
			dark
			sort-by="dateCreated"
			:sort-desc="true"
			:footer-props="{
				'items-per-page-options':[5, 10, 20, -1],
				'prevIcon': 'fa fa-chevron-left',
				'nextIcon': 'fa fa-chevron-right'
			}"
		>
			<template v-slot:item.active="{ item }">
				<div :class="currentActiveKey(type) === item._key ? 'active' : 'not-active'"/>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn
							icon
							class="mx-0"
							v-on="on"
							@click.stop="openModal(item)"
						>
							<v-icon size="20">fa fa-upload</v-icon>
						</v-btn>
					</template>
					Set as active
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn
							icon
							class="mx-0"
							v-on="on"
							@click.stop="testVersion(item)"
						>
							<v-icon
								size="20"
								color="green"
							>
								fa fa-check
							</v-icon>
						</v-btn>
					</template>
					Test
				</v-tooltip>
			</template>
			<template v-slot:item.dateCreated="{ item }">
				{{ item.dateCreated | formatDate }}
			</template>
		</v-data-table>
		<confirmation-modal
			v-if="confirmationModalOpen"
			:item="selectedItem"
			:text="promptText"
			@setActive="setAsActive"
			@cancel="cancel"
		/>
	</div>
</template>

<style scoped lang="scss">
.v-btn:hover {
	background-color: darkgrey;
}
.active {
	width: 100%;
	height: 100%;
	background-color: #0c5900;
}
.not-active {
	width: 100%;
	height: 100%;
	background-color: #6b0000;
}
</style>
