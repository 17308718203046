import {
	UPDATE_LOCAL_EXTENSIONS,
	UPDATE_LOCAL_SYNONYMS,
	UPDATE_LOCAL_EXISTING_EXTENSIONS,
	UPDATE_LOCAL_EXISTING_SYNONYMS,
	UPDATE_ACTIVE,
	CLEAR_LOCAL,
	UPDATE_ACTIVE_KEY
} from "../mutation-types";
import { phoneMenuKey } from "@dashboard/utils/app-state-keys";

const state = {
	localExtensions: null,
	localSynonyms: null,
	localExistingExtension: null,
	localExistingSynonym: null,
	activeSynonym: null,
	activeExtension: null
};

// getters
const getters = {
	localExtensions: state => state.localExtensions,
	localSynonyms: state => state.localSynonyms,
	localExistingExtension: state => state.localExistingExtension,
	localExistingSynonym: state => state.localExistingSynonym,
	activeSynonym: state => state.activeSynonym,
	activeExtension: state => state.activeExtension
};

// actions
const actions = {
	updateLocalExtensions({ commit }, extensions) {
		commit(UPDATE_LOCAL_EXTENSIONS, extensions);
	},
	updateLocalSynonyms({ commit }, synonyms) {
		commit(UPDATE_LOCAL_SYNONYMS, synonyms);
	},
	updateLocalExistingExtensions({ commit }, extension) {
		commit(UPDATE_LOCAL_EXISTING_EXTENSIONS, extension);
	},
	updateLocalExistingSynonyms({ commit }, synonym) {
		commit(UPDATE_LOCAL_EXISTING_SYNONYMS, synonym);
	},
	clearLocal({ commit }, type) {
		commit(CLEAR_LOCAL, type)
	},
	getActiveContent({ commit, dispatch }, type) {
		return dispatch("request/getContent", type, { root: true })
			.then(active => {
				commit(UPDATE_ACTIVE, { type, active });
				commit(`dataHandler/${UPDATE_ACTIVE_KEY}`, { type, active }, { root: true })
			})
	}
};

// mutations
const mutations = {
	[UPDATE_LOCAL_EXTENSIONS](state, extensions) {
		state.localExistingExtension = null;
		state.activeExtension = null;
		state.localExtensions = extensions;
	},
	[UPDATE_LOCAL_SYNONYMS](state, synonyms) {
		state.localExistingSynonym = null;
		state.activeSynonym = null;
		state.localSynonyms = synonyms;
	},
	[UPDATE_LOCAL_EXISTING_EXTENSIONS](state, synonym) {
		state.localExtensions = null;
		state.activeExtension = null;
		state.localExistingExtension = synonym;
	},
	[UPDATE_LOCAL_EXISTING_SYNONYMS](state, synonym) {
		state.localSynonyms = null;
		state.activeSynonym = null;
		state.localExistingSynonym = synonym;
	},
	[CLEAR_LOCAL](state, type) {
		state[`local${type}`] = null;
	},
	[UPDATE_ACTIVE](state, { type, active }) {
		if (type === phoneMenuKey) {
			state.localExtensions = null,
			state.localExistingExtension = null,
			state.activeExtension = active.data;
		} else {
			state.localSynonyms = null,
			state.localExistingSynonym = null,
			state.activeSynonym = active.data;
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
