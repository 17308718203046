<script>
import { mdiChevronRight } from "@mdi/js";
const httpPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;
const wwwPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

export default {
	props: {
		extension: {
			type: Object,
			default: () => {}
		},
		backgroundColor: {
			type: String,
			default: ""
		},
		textColor: {
			type: String,
			default: ""
		},
		btnColor: {
			type: String,
			default: ""
		},
		searchText: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			icons: {
				mdiChevronRight
			},
			showFullDescriptionNames: false
		};
	},
	computed: {
		formatedDescriptionString() {
			const descriptionCopy = this.extension.description.toLowerCase();
			const indexOfSearchTerm = descriptionCopy.indexOf(this.searchText.toLowerCase());
			if (indexOfSearchTerm !== -1) {
				const prependString = this.extension.description.substring(0, indexOfSearchTerm);
				const endOfSearchTerm = indexOfSearchTerm + this.searchText.length;
				const searchedString = this.extension.description.substring(indexOfSearchTerm, endOfSearchTerm);
				const appendString = this.extension.description.substring(endOfSearchTerm, this.extension.description.length);
				return `<span>${prependString}</span><span style="background-color: rgba(255, 227, 25, 1); color: black">${searchedString}</span><span>${appendString}</span>`;
			}
			return `<span>${this.extension.description}</span>`;
		},
		formattedDescriptionNames() {
			if (this.extension.productNames) {
				return this.extension.productNames.map(name => {
					const descriptionCopy = name.toLowerCase();
					const indexOfSearchTerm = descriptionCopy.indexOf(this.searchText.toLowerCase());
					const nameIncludesLink = name.includes("www.") || name.includes("http");
					if (nameIncludesLink) {
						return name.replace(httpPattern, "<a @click.stop=\"\" href=\"$&\">$&</a>").replace(wwwPattern, "$1<a @click.stop=\"\" href=\"http://$2\">$2</a>");
					}
					if (indexOfSearchTerm !== -1 && !nameIncludesLink) {
						const prependString = name.substring(0, indexOfSearchTerm);
						const endOfSearchTerm = indexOfSearchTerm + this.searchText.length;
						const searchedString = name.substring(indexOfSearchTerm, endOfSearchTerm);
						const appendString = name.substring(endOfSearchTerm, name.length);
						return `<span>${prependString}</span><span style="background-color: rgba(255, 227, 25, 1); color: black">${searchedString}</span><span>${appendString}</span>`;
					}
					return `<span>${name}</span>`;
				});
			}
			return [];
		},
		minimumDescriptionNames() {
			return this.formattedDescriptionNames.slice(0, 3);
		},
		maximumDescriptionNames() {
			return this.formattedDescriptionNames.slice(0, 200);
		},
		fullViewButtonColor() {
			return this.$vuetify.theme.themes[this.$_theme].searchResultButton;
		}
	},
	methods: {
		toggleFullView() {
			if (this.showFullDescriptionNames) {
				this.showFullDescriptionNames = false;
			} else {
				this.showFullDescriptionNames = true;
			}
		}
	}
};
</script>

<template>
	<v-card
		class="mb-2"
		:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
	>
		<v-container class="py-0 ">
			<v-row
				v-ripple
				@click="$emit('selectExtension', extension)"
			>
				<v-col
					cols="1"
					class="pl-0 py-0 pr-5 kmColorBand"
					:style="{ backgroundColor: backgroundColor }"
				/>
				<v-col
					cols="9"
					class="pl-0"
				>
					<div :style="{ color: textColor }">Extension: {{ extension.extensionPath.join(".") }}</div>
					<p
						class="subtitle-1 mb-0"
						:style="{ color: textColor }"
					>
						<span v-html="formatedDescriptionString" />
					</p>
					<div v-if="formattedDescriptionNames.length">
						<v-divider class="mb-2 mt-2" />
						<div v-if="showFullDescriptionNames">
							<p
								v-for="(name, index) in maximumDescriptionNames"
								:key="index"
								class="caption mb-0"
								:style="{ color: textColor }"
							>
								<span v-html="name" />
							</p>
						</div>
						<div v-else>
							<p
								v-for="(name, index) in minimumDescriptionNames"
								:key="index"
								class="caption mb-0"
								:style="{ color: textColor }"
							>
								<span v-html="name" />
							</p>
						</div>
						<v-btn
							v-if="formattedDescriptionNames.length > 3"
							class="mt-2"
							:color="fullViewButtonColor"
							small
							block
							@click.stop="toggleFullView"
						>
							{{ showFullDescriptionNames ? `Show less` : `Show ${formattedDescriptionNames.length - 3} more` }}
						</v-btn>
					</div>
				</v-col>
				<v-col
					class="chooseExtension px-0"
					cols="2"
				>
					<v-container
						fill-height
						fill-width
					>
						<v-row
							no-gutters
						>
							<v-col
								align="center"
								justify="start"
							>
								<v-icon
									size="30"
									:color="btnColor"
								>
									{{ icons.mdiChevronRight }}
								</v-icon>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<style lang="css" scoped>
.kmColorBand {
	border-radius: 4px 0px 0px 4px;
	background-clip: content-box;
}
</style>
