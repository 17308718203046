<script>
import extensionsBgColor from "../../../mixins/extensions-bg-color";
import moment from "moment";
import { mdiPhone, mdiMenuRight } from "@mdi/js";
export default {
	mixins: [extensionsBgColor],
	props: {
		node: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		icons: {
			mdiPhone,
			mdiMenuRight
		}
	}),
	computed: {
		formattedNode() {
			const nodeCopy = JSON.parse(JSON.stringify(this.node));
			nodeCopy.dateCreated = moment(nodeCopy.dateCreated).format("dddd - DD/MM/YYYY");
			return nodeCopy;
		},
		rootExtension() {
			return this.node.extension[0];
		},
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].kongsbergBlue;
		},
		extensionBackgroundColor() {
			return this.getBackgroundColor(this.rootExtension);
		},
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		}
	},
	methods: {
		decideBackgroundColor() {
			if (this.$_theme === "dark") {
				return "rgba(30, 30, 30, 0.94)";
			}
			return "rgba(255, 255, 255, 1)";
		}
	}
};
</script>

<template>
	<div>
		<v-card
			class="mb-4"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
		>
			<v-container class="py-0">
				<v-row>
					<v-col
						cols="10"
						class="py-3"
					>
						<div :style="{ color: textColor }">Extension: {{ formattedNode.extension.join(".") }}</div>
						<div :style="{ color: textColor }">Called: {{ formattedNode.dateCreated }}</div>

						<span
							v-for="(description, index) in formattedNode.descriptions"
							:key="index"
							style="font-size: 13px;"
							:style="{ color: textColor }"
						>
							{{ description }}
							<v-icon v-if="index + 1 !== formattedNode.descriptions.length">
								{{ icons.mdiMenuRight }}
							</v-icon>
						</span>
					</v-col>
					<v-col
						class="callNode px-0"
						cols="2"
						@click="$emit('call', node)"
					>
						<v-container fill-height>
							<v-row
								align="center"
								justify="start"
							>
								<div class="pl-3">
									<v-icon
										size="30"
										color="green"
									>
										{{ icons.mdiPhone }}
									</v-icon>
								</div>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<style lang="css" scoped>
.callNode {
	border-radius: 0px 4px 4px 0px;
}
</style>
