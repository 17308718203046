import iconv from "iconv-lite";
import encodingChecker from "encoding-japanese";

export default function(fileBuffer, encoding) {
	const detectedEncoding = encodingChecker.detect(fileBuffer);
	let converter = data => data;
	if (detectedEncoding.toLowerCase() !== encoding) {
		converter = data => {
			const decoded = iconv.decode(fileBuffer, detectedEncoding);
			return iconv.encode(decoded, encoding);
		};
	}
	return converter(fileBuffer);
}
