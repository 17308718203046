import Vue from "vue";
import {
	UPDATE_SHOWN_STATE
} from "../mutation-types";

const state = {
	shownThisSession: false
};

// getters
const getters = {
	welcomeShownThisSession: state => state.shownThisSession
};

// actions
const actions = {
	updateShownState({ commit }, shown) {
		commit(UPDATE_SHOWN_STATE, shown);
	}
};

// mutations
const mutations = {
	[UPDATE_SHOWN_STATE](state, shown) {
		state.shownThisSession = shown;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
