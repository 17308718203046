import { request } from "../libs";
import store from '../../store';
const timeout = 8000;

function resetPassword({ id, nonce, password }) {
	return request(
		store.getters["env/serverUrl"] + "/password-reset",
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ id, nonce, password })
		},
		timeout
	);
}

export default resetPassword;
