import { request } from "../libs";

export default (serverUrl, id) => {
	return request(
		`${serverUrl}/search-list/${id}`,
		{
			method: "GET"
		}
	)
		.then(response => response.json());
};
