<script>
import extensionsBgColor from "../../../mixins/extensions-bg-color";
import { mapGetters, mapActions } from "vuex";
import { mdiChevronRight } from "@mdi/js";
import EventBus from "../../../mixins/event-bus";

export default {
	mixins: [extensionsBgColor],
	props: {
		filteredExtensions: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		icons: {
			mdiChevronRight
		}
	}),
	computed: {
		...mapGetters("extensionsSelector", ["currentDepth"]),
		extraBottomSpacing() {
			return this.$route.path === "/" && this.currentDepth > 1;
		},
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].menuRightButton;
		},
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		}
	},
	watch: {
		filteredExtensions() {
			window.scroll(0, 0);
		}
	},
	methods: {
		...mapActions("extensionsSelector", ["updateExtension", "incrementDepth"]),
		addExtension(extension) {
			setTimeout(() => {
				return this.updateExtension(extension.extensionPath)
					.then(() => this.incrementDepth(extension.depth))
					.then(() => EventBus.$emit("reEvaluateScrollArrow"));
			}, 100);
		},
		decideBackgroundColor(rootExtension) {
			return this.getBackgroundColor(rootExtension);
		}
	}
};
</script>

<template>
	<div :style="{ marginBottom: extraBottomSpacing ? 58 + 'px' : 0 + 'px' }">
		<v-card
			v-for="extension in filteredExtensions"
			:key="extension.description"
			class="mb-4 mr-0"
			:class="$_theme === 'dark' ? 'darkBoxStyling' : ''"
		>
			<v-container class="py-0 ">
				<v-row
					v-ripple
					@click="addExtension(extension)"
				>
					<v-col
						cols="1"
						class="pl-0 py-0 pr-5 kmColorBand"
						:style="{ backgroundColor: decideBackgroundColor(extension.extensionPath[0]) }"
					/>
					<v-col
						cols="9"
						class="pl-0"
					>
						<div :style="{ color: textColor }">Extension: {{ extension.extensionPath.join(".") }}</div>
						<p
							class="subtitle-1 mb-0"
							:style="{ color: textColor }"
						>
							{{ extension.description }}
						</p>
					</v-col>
					<v-col
						class="chooseExtension px-0"
						cols="2"
					>
						<v-container
							fill-height
							fill-width
						>
							<v-row no-gutters>
								<v-col
									align="center"
									justify="start"
								>
									<v-icon
										size="30"
										:color="btnColor"
									>
										{{ icons.mdiChevronRight }}
									</v-icon>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<style lang="css" scoped>
.kmColorBand {
	border-radius: 4px 0px 0px 4px;
	background-clip: content-box;
}
</style>
