<script>
import EventBus from "../../utils/EventBus";
import csv from "csv-parser";
import fileReaderStream from "filereader-stream";
import concatStream from "concat-stream";
import bufferToStream from "buffer-to-stream";
import parseSynonymExcel from "../../utils/parse-synonym-excel";
import ensureEncoding from "../../utils/ensure-encoding";
import { mapActions } from "vuex";
import { mdiFileUploadOutline } from "@mdi/js";
import VueFilePicker from "../elements/Vue-File-Picker.vue";

export default {
	components: {
		VueFilePicker
	},
	data() {
		return {
			selectedFile: {},
			icons: {
				mdiFileUploadOutline
			}
		}
	},
	methods: {
		...mapActions("parsedExcel", ["updateLocalSynonyms", "clearLocal"]),
		synonymSelected(files) {
			const selectedFile = files[0]
			this.selectedFile = selectedFile;
			let results = [];

			const parseData = concatStream(fileBuffer => parseBuffer(fileBuffer));

			fileReaderStream(selectedFile)
				.pipe(parseData);

			const vm = this;
			function parseBuffer(fileBuffer) {
				const data = ensureEncoding(fileBuffer, "utf8");

				bufferToStream(data)
					.pipe(csv({ separator: ";" }))
					.on("data", data => {
						try {
							results.push(parseSynonymExcel(data));
						} catch (error) {
							alert("Unable to parse file, there might be some issues in the uploaded file.")
							throw error
						}
					})
					.on("end", () => {
						vm.updateLocalSynonyms({ fileName: selectedFile.name, data: results});
					});
				}
		},
		clearSynonymsFileSelect() {
			this.selectedFile = {};
			return this.clearLocal("Synonyms");
		}
	},
	mounted() {
		EventBus.$on("clearSynonymsFileSelect", this.clearSynonymsFileSelect);
	},
	beforeDestroy() {
		EventBus.$off("clearSynonymsFileSelect", this.clearSynonymsileSelect);
	}
};
</script>

<template>
	<div>
		<vue-file-picker
			id="synonymPicker"
			inputId="synonymFilePicker"
			emitMessage="synonymListSelected"
			v-on:synonymListSelected="synonymSelected"
		>
			<template slot="icon">
				<v-icon large>
					{{ icons.mdiFileUploadOutline }}
				</v-icon>
			</template>
			<template slot="file">
				{{ selectedFile.name }}
			</template>
		</vue-file-picker>
	</div>
</template>
