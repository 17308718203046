<script>
import TopNavBar from "./src-dashboard/components/views/Top-Nav-Bar.vue";
import { mapGetters } from "vuex";
export default {
	name: "AppWrapper",
		components: {
		TopNavBar
	},
	computed: {
		...mapGetters("session", ["userIsLoggedIn", "validSession"])
	}
};
</script>

<template>
	<div id="app">
		<v-app>
			<v-content>
				<top-nav-bar v-if="validSession" />
				<router-view />
			</v-content>
		</v-app>
	</div>
</template>

<style scoped lang="scss">
.mobile-app-container {
	overflow-y: auto;
	border-left: 1px solid #383838;
}
</style>
