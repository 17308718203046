<script>
import BackButton from "./components/layouts/Back-Button.vue";

export default {
	name: "App",
	components: {
		BackButton
	},
	$dbQuery: {
		darkModeSettings() {
			return {
				default: {},
				selector: {
					id: "darkModeSettings"
				},
				limit: 1,
				db: this.$_dbCallAppConfig.name
			};
		}
	},
	computed: {
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].scrollArrow;
		},
		textColor() {
			return this.$vuetify.theme.themes[this.$_theme].textColor;
		}
	}
};
</script>

<template>
	<v-app
		id="app"
		style="background-color: rgba(0, 0, 0, 0)"
		class="ml-3"
	>
		<back-button />
		<v-divider />
		<v-content>
			<router-view />
		</v-content>
	</v-app>
</template>

<style lang="css">
html {
	touch-action: manipulation;
}
.scroolToSeeMoreButton {
	margin-bottom: 25px !important;
}
@media screen and (min-width: 470px) {
	.backgroundImage img {
		width: 100% !important;
		object-fit: cover !important;
	}
}
.whiteBlurText {
	opacity: 0.9 !important;
	color: white !important;
}
.darkBoxStyling {
	background-color: rgba(30, 30, 30, 0.94) !important;
}
</style>
