<script>
import isValidPassword from "../../../utils/is-valid-password.js";
import { validateNonce } from "../../api";
import { resetPassword } from "../../api";

export default {
	data() {
		return {
			passwordChangeSuccess: false,
			requestError: "",
			isFormValid: false,
			isNonceValid: false,
			rules: {
				required: v => !!v || "Required",
				password: v => v && isValidPassword(v) || "Password must be at least 12 characters long, and contain at least a lowercase letter, one number, a special character and one uppercase letter",
				passwordMatch: v => v && this.isPasswordsMatching(v) || "Passwords must match"
			}
		};
	},
	computed: {
		params() {
			let params = {};
			let q = window.location.search.substring(1);
			let parts = q.split("&");
			parts.forEach(part => {
				let kv = part.split("=");
				params[kv[0]] = kv[1];
			});
			return params;
		}
	},
	methods: {
		isPasswordsMatching(value) {
			const password = document.getElementById("passwordInputField").value;
			if (password === value) {
				return true;
			}
			return false;
		},
		registerNewPassword() {
			const password = document.getElementById("passwordInputField").value;
			if (password && this.params.id && this.params.nonce) {
				return resetPassword({ password, id: this.params.id, nonce: this.params.nonce })
					.then(() => {
						this.passwordChangeSuccess = true;
					})
					.catch(err => {
						this.requestError = err.message;
					});
			}
			return this.requestError = "Both fields must be filled out and match";
		},
		nonceValidation() {
			return validateNonce({ userId: this.params.userId, id: this.params.id, nonce: this.params.nonce })
				.then(response => {
					if (response.isNonceValid && response.isUserIdMatched) {
						return true;
					}
					if (!response.isNonceValid) {
						this.requestError = "Link is invalid, expired or already used. Please try again with a new password link.";
					} else if (!response.isUserIdMatched) {
						this.requestError = "Invalid user. Please try again with a new password link.";
					}
					return false;
				})
				.catch(() => {
					this.requestError = "URL of this page is invalid because of missing user, or required parameters in the url. Try again with a new password reset link!";
					return false;
				});
		}
	},
	beforeMount() {
		this.nonceValidation()
			.then(isValid => this.isNonceValid = isValid);
	}
};
</script>

<template>
	<v-app id="inspire">
		<v-container
			fluid
			fill-height
			style="width:1800px"
		>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md5>
					<v-card class="elevation-12 box px-4 py-4">
						<div>
							<img
								src="../../../../resources/kongsberg_horizontal_light.png"
								width="50%"
							/>
						</div>
						<v-card-text v-if="!passwordChangeSuccess">
							<v-form
								@submit.prevent="registerNewPassword"
								v-model="isFormValid"
							>
								<v-row>
									<p class="user">Username: {{ params.username || "-" }}</p>
								</v-row>
								<v-row>
									<v-text-field
										label="New password"
										prepend-inner-icon="fa fa-lock"
										outlined
										type="password"
										id="passwordInputField"
										:rules="[rules.required, rules.password]"
										dense
									/>
								</v-row>
								<v-row>
									<v-text-field
										label="Repeat new password"
										prepend-inner-icon="fa fa-lock"
										outlined
										type="password"
										id="passwordRepeatInputField"
										:rules="[rules.required, rules.passwordMatch]"
										dense
									/>
								</v-row>
								<v-row>
									<v-btn
										block
										large
										color="primary"
										type="submit"
										:disabled="!isFormValid || !isNonceValid"
									>
										Submit
									</v-btn>
								</v-row>
								<v-row
									v-if="requestError"
									style="color: red; padding: 15px"
								>
									{{ requestError }}
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-text v-else>
							<v-row>
								<p class="user">Username: {{ params.username || "-" }}</p>
							</v-row>
							<v-row>
								<p>Your password was successfully saved.</p>
							</v-row>
							<v-row>
								<router-link :to="{name: 'routeResolver'}">
									<v-btn
										block
										large
										color="primary"
									>
										Continue to login
									</v-btn>
								</router-link>
							</v-row>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<style lang="scss" scoped>
</style>