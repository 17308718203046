export default function(data) {
	const extensionPathSpares = data.Spares !== "" ? data.Spares.split(".") : null;
	const extensionPathTechsup = data.Techsup !== "" ? data.Techsup.split(".") : null;
	const depth = extensionPathTechsup.length;
	const productName = data["Product name"] !== "" ? data["Product name"] : "";
	const productFamily = data["Product Family"] !== "" ? data["Product Family"] : "";
	const typeAndRemarks = data["Type Designation and Remarks"] !== "" ? data["Type Designation and Remarks"] : "";
	const teachSupportTeam = data["Technical Support team"] !== "" ? data["Technical Support team"] : "";
	const remark = data.Remark !== "" ? data.Remark : "";
	return {
		extensionPathSpares,
		extensionPathTechsup,
		depth,
		productName,
		productFamily,
		typeAndRemarks,
		teachSupportTeam,
		remark
	};
}
