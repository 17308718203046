<script>
// const Fuse = require("fuse.js");
import Nodes from "./Nodes.vue";
import Call from "./Call.vue";
import Search from "./Search.vue";
import SearchResult from "./search/Search-Result.vue";
import kmSearchSynonym from "../../../../resources/km-search-synonym.json";
import sortSearchResults from "../../../utils/sort-search-results";
import { mapGetters } from "vuex";

export default {
	components: {
		Nodes,
		Call,
		Search,
		SearchResult
	},
	props: {
		extensions: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		searchText: "",
		defaultSynonyms: kmSearchSynonym
	}),
	computed: {
		...mapGetters("extensionsSelector", ["currentDepth", "selectedExtensions"]),
		...mapGetters("parsedExcel", ["localSynonyms", "activeSynonym", "localExistingSynonym"]),
		formattedLocalSynonyms() {
			return this.localSynonyms ? this.localSynonyms.data : null;
		},
		kmSearchSynonym() {
			return this.activeSynonym || this.formattedLocalSynonyms || this.localExistingSynonym || [];
		},
		extensionsToShow() {
			return this.extensions[this.currentDepth] || [];
		},
		filteredExtensions() {
			if (this.currentDepth > "1") {
				return this.extensionsToShow.filter(extension => {
					return extension.extensionPath.slice(0, -1).join("") === this.selectedExtensions.join("");
				});
			}
			return this.extensionsToShow;
		},
		allExtensionsArray() {
			return Object.values(this.extensions).reduce((acc, depth) => {
				acc = [...acc, ...depth];
				return acc;
			}, []);
		},
		showSearchResult() {
			return this.formattedSearchText.length > 1;
		},
		searchedExtensions() {
			if (this.showSearchResult) {
				const callExtensionsResults = this.allExtensionsArray.filter(extension => {
					return extension.description.toLowerCase().indexOf(this.formattedSearchText.toLowerCase()) > -1;
				});
				const callSynonymResults = this.kmSearchSynonym.filter(synonym => {
					const valuesToSearch = synonym.productName + synonym.productFamily + synonym.typeAndRemarks + synonym.teachSupportTeam + synonym.remark;
					return valuesToSearch.toLowerCase().indexOf(this.formattedSearchText.toLowerCase()) > -1;
				});
				const combinedResults = this.combineSearchResults(callExtensionsResults, callSynonymResults);
				const formatedResults = this.formatSearchResult(combinedResults);
				return formatedResults;
			}
			return [];
		},
		formattedSearchText() {
			return this.searchText.trim().replace(/\s\s+/g, " ");
		},
		/*		searchedExtensions() {
			if(this.showSearchResult) {
				const callExtensionsResults = this.getSearchResults(this.allExtensionsArray, [ "description", "extensionPath" ]);
				const callSynonymResults = this.getSearchResults(this.kmSearchSynonym, [
					"productName",
					"productFamily",
					"typeAndRemarks",
					"teachSupportTeam",
					"remark"
					]);
				const combinedResults = this.combineSearchResults(callExtensionsResults, callSynonymResults);
				const formatedResults = this.formatSearchResult(combinedResults);
				return formatedResults;
			}
			return [];
		}, */
		maxDepthReached() {
			return !this.filteredExtensions.length;
		}
	},
	methods: {
		search(value) {
			this.searchText = value;
		},
		/*		getSearchResults(searchableValues, keys) {
			const fuzzySearch = new Fuse(searchableValues, {
				matchAllTokens: true,
				tokenize: true,
				keys
			});
			return fuzzySearch.search(this.searchText);
		}, */
		combineSearchResults(extensionResults, synonymResults) {
			const combinedResults = [...extensionResults, ...synonymResults];
			return combinedResults.reduce((acc, entry) => {
				if (entry.extensionPath) {
					const stringPath = entry.extensionPath.join().trim();
					if (!acc[stringPath]) {
						acc[stringPath] = { type: "extension" };
					}
				} else {
					const stringPathSpare = entry.extensionPathSpares ? entry.extensionPathSpares.join().trim() : undefined;
					const stringPathTech = entry.extensionPathTechsup ? entry.extensionPathTechsup.join().trim() : undefined;
					if (stringPathSpare && !acc[stringPathSpare]) {
						acc[stringPathSpare] = { type: "synonym", productNames: [entry.productName] };
					} else if (stringPathSpare && acc[stringPathSpare] && acc[stringPathSpare].type !== "extension") {
						const exists = acc[stringPathSpare].productNames.some(name => name === entry.productName);
						if (!exists) {
							acc[stringPathSpare].productNames.push(entry.productName);
						}
					}
					if (stringPathTech && !acc[stringPathTech]) {
						acc[stringPathTech] = { type: "synonym", productNames: [entry.productName] };
					} else if (stringPathTech && acc[stringPathTech] && acc[stringPathTech].type !== "extension" && stringPathTech !== stringPathSpare) {
						const exists = acc[stringPathTech].productNames.some(name => name === entry.productName);
						if (!exists) {
							acc[stringPathTech].productNames.push(entry.productName);
						}
					}
				}
				return acc;
			}, {});
		},
		formatSearchResult(combinedResults) {
			return Object.entries(combinedResults).reduce((acc, [key, value]) => {
				let foundEntry = this.allExtensionsArray.find(entry => entry.extensionPath.join() === key);
				if (!foundEntry) {
					console.warn(`Found search result for extension: ${key}, but no corresponding extension entry`);
				} else if (foundEntry.depth > 1) {
					const previousEntry = this.allExtensionsArray.find(entry => entry.extensionPath.join() === key.slice(0, -2));
					foundEntry = JSON.parse(JSON.stringify(foundEntry));
					foundEntry.description = `${previousEntry.description} > ${foundEntry.description}`;
				}
				if (foundEntry !== undefined) {
					if (value.type === "synonym") {
						foundEntry.productNames = sortSearchResults(value.productNames, this.searchText) || [];
					}
					acc[value.type].push(foundEntry);
				}
				return acc;
			}, { extension: [], synonym: [] });
		}
	}
};
</script>

<template>
	<div>
		<v-col class="px-1 py-1 mt-2">
			<search
				v-if="!maxDepthReached"
				class="mb-3"
				@search="search"
			/>
			<call
				v-if="!showSearchResult"
				:max-depth-reached="maxDepthReached"
			/>
			<search-result
				v-if="showSearchResult"
				:searched-extensions="searchedExtensions"
				:search-text="formattedSearchText"
			/>
			<nodes
				v-else
				:filtered-extensions="filteredExtensions"
			/>
		</v-col>
	</div>
</template>
