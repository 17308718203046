<script>
// import SelectNumber from "./setup/Select-Number.vue";
import SelectEmailConfiguration from "./setup/Select-Email-Configuration.vue";
import ToggleTheme from "./setup/Toggle-Theme.vue";
export default {
	components: {
		// SelectNumber,
		ToggleTheme,
		SelectEmailConfiguration
	}
};
</script>

<template>
	<v-container class="pt-0 px-0">
		<v-col class="px-1 py-1 mt-2">
			<!-- <select-number /> -->
			<toggle-theme />
			<select-email-configuration />
		</v-col>
	</v-container>
</template>
