import { request } from "../libs";
import store from '../../store';
const timeout = 8000;

function requestPasswordReset({ user, email }) {
	return request(
		store.getters["env/serverUrl"] + "/request-password-reset",
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ username: user, email })
		},
		timeout
	);
}

export default requestPasswordReset;
