import { request } from "../libs";
import store from '../../store';
const timeout = 3000;

export default function refreshToken() {
	const accessToken = localStorage.getItem("access_token");
	const refreshToken = localStorage.getItem("refresh_token");

	if (!accessToken || !refreshToken) {
		return Promise.reject();
	}

	return request(
		`${store.getters["env/serverUrl"]}/auth/refresh-token`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ accessToken, refreshToken })
		},
		timeout
	)
		.then(response => response.json())
		.then(body => {
			localStorage.setItem("access_token", `Bearer ${body.accessToken.token}`);
			localStorage.setItem("refresh_token", body.refreshToken.token);
		})
}