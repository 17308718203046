<script>
import { mapGetters, mapActions } from "vuex";
import { mdiUndo } from "@mdi/js";
import EventBus from "../../mixins/event-bus";

export default {
	data: () => ({
		icons: {
			mdiUndo
		}
	}),
	computed: {
		...mapGetters("extensionsSelector", ["currentDepth"]),
		btnColor() {
			return this.$vuetify.theme.themes[this.$_theme].blueButtons;
		}
	},
	methods: {
		...mapActions("extensionsSelector", ["oneStepBack"]),
		goOneStepBack() {
			if (this.currentDepth > 1) {
				return this.oneStepBack()
					.then(() => EventBus.$emit("reEvaluateScrollArrow"));
			}
		}
	}
};
</script>

<template>
	<div
		class="text-center py-3"
		style="cursor: pointer;"
		:class="{ 'disbled-button': currentDepth === 1 }"
		@click.stop="goOneStepBack"
	>
		<v-icon
			:color="btnColor"
			:class="{ 'disbled-button': currentDepth === 1 }"
		>
			{{ icons.mdiUndo }}
		</v-icon> Back
	</div>
</template>

<style scoped>
.disbled-button {
	color: grey !important;
	cursor: not-allowed;
}
</style>