import {
	SET_USER_LOGGED_OUT,
	SET_USER_LOGGED_IN,
	SET_USERNAME,
	SET_VALID_SESSION,
	SET_INVALID_SESSION
} from "../mutation-types";

const state = {
	userIsLoggedIn: false,
	username: localStorage.username || "",
	validSession: localStorage.validSession || false,
	userInfo: {}
};

const getters = {
	userIsLoggedIn: state => state.userIsLoggedIn,
	username: state => state.username,
	userInfo: state => state.userInfo,
	validSession: state => state.validSession
};

const actions = {
	logout({ commit, dispatch }) {
		const refreshToken = localStorage.getItem("refresh_token");
		Promise.all([
			localStorage.removeItem("access_token"),
			localStorage.removeItem("refresh_token"),
			localStorage.removeItem("validSession"),
			dispatch("request/logout", refreshToken, { root: true }),
			commit(SET_USER_LOGGED_OUT),
			commit(SET_INVALID_SESSION)
		]);
	},
	setUsername({ commit }, username) {
		commit(SET_USERNAME, username);
	},
	setUserLoggedIn({ commit }) {
		commit(SET_USER_LOGGED_IN);
		commit(SET_VALID_SESSION);
	},
	setUserLoggedOut({ commit }) {
		commit(SET_USER_LOGGED_OUT);
		commit(SET_INVALID_SESSION);
	},
	setValidSession({ commit }) {
		commit(SET_VALID_SESSION);
	}
};

const mutations = {
	SET_USERNAME(state, username) {
		localStorage.setItem("username", username);
		state.username = username;
	},
	SET_USER_LOGGED_IN(state) {
		state.userIsLoggedIn = true;
	},
	SET_USER_LOGGED_OUT(state) {
		state.userIsLoggedIn = false;
		localStorage.removeItem("validSession");
	},
	SET_VALID_SESSION(state) {
		localStorage.setItem("validSession", true);
		state.validSession = true;
	},
	SET_INVALID_SESSION(state) {
		state.validSession = false;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
