import Vue from "vue";
import Router from "vue-router";
import CallSelect from "../components/views/Call-Select.vue";
import CallHistory from "../components/views/Call-History.vue";
import Setup from "../components/views/Setup.vue";
import About from "../components/views/About.vue";

Vue.use(Router);

export default new Router({
	routes: [
		{
			path: "/",
			name: "callSelect",
			component: CallSelect
		},
		{
			path: "/call-history",
			name: "callHistory",
			component: CallHistory
		},
		{
			path: "/setup",
			name: "setup",
			component: Setup
		},
		{
			path: "/about",
			name: "about",
			component: About
		}
	]
});
