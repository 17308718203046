<script>
import EventBus from "./utils/EventBus";
import AddExtensionList from "./components/views/Add-Extension-List.vue";
import AddSynonymList from "./components/views/Add-Synonym-List.vue";
import History from "./components/elements/History.vue";
import ConfirmationModal from "./components/elements/Confirmation-Modal.vue";
import { mapGetters, mapActions } from "vuex";
import { phoneMenuKey, searchListKey } from "./utils/app-state-keys";
export default {
	components: {
		AddExtensionList,
		AddSynonymList,
		History,
		ConfirmationModal
	},
	data() {
		return {
			phoneMenuKey,
			searchListKey,
			confirmationModalOpen: false,
			typeToPrompt: "",
			textToPrompt: ""
		};
	},
	computed: {
		...mapGetters("parsedExcel", ["localExtensions", "localSynonyms"])
	},
	methods: {
		...mapActions("request", ["postExtension", "postSynonym", "selectContent", "getExtension", "getSynonym"]),
		...mapActions("parsedExcel", ["getActiveContent", "updateLocalExistingExtensions", "updateLocalExistingSynonyms"]),
		...mapActions("dataHandler", ["getAllExtensions", "getAllSynonyms"]),
		uploadExtension() {
			return this.postExtension(JSON.stringify(this.localExtensions))
				.then(response => {
					console.log(response);
					this.clearInputFIeld("Extensions")
					return this.updateDataLists()
						.then(() => response._key);
				})
				.then(key => {
					return this.getExtension(key)
						.then(extension => {
							this.updateLocalExistingExtensions(extension.data);
						});
				})
				.catch(this.handleBadRequest);
		},
		uploadSynonym() {
			return this.postSynonym(JSON.stringify(this.localSynonyms))
				.then(response => {
					this.clearInputFIeld("Synonyms")
					return this.updateDataLists()
						.then(() => response._key);
				})
				.then(key => {
					return this.getSynonym(key)
						.then(synonym => {
							this.updateLocalExistingSynonyms(synonym.data);
						});
				})
				.catch(this.handleBadRequest);
		},
		handleBadRequest(e) {
			if (typeof e.json === "function") {
				return e.json()
					.then(({ message }) => {
						alert(message);
					})
			}
			throw e;
		},
		uploadExtensionAndSetActive() {
			return this.postExtension(JSON.stringify(this.localExtensions))
				.then(result => {
					return this.selectContent({
						contentKey: this.phoneMenuKey,
						documentHandle: result._key
					})
				})
				.then(() => {
					this.clearInputFIeld("Extensions")
					return this.updateDataLists()
				});
		},
		uploadSynonymAndSetActive() {
			return this.postSynonym(JSON.stringify(this.localSynonyms))
				.then(result => this.selectContent({
					contentKey: this.searchListKey,
					documentHandle: result._key
				}))
				.then(() => {
					this.clearInputFIeld("Synonyms")
					return this.updateDataLists()
				});
		},
		updateDataLists() {
			return this.getAllExtensions()
				.then(() => this.getAllSynonyms())
				.then(() => this.getActiveContent(phoneMenuKey))
				.then(
					() => this.getActiveContent(searchListKey),
					() => this.getActiveContent(searchListKey)
				);
		},
		promptSelection(type) {
			this.typeToPrompt = type;
			this.textToPrompt = `Setting these ${type} as active will immediatly change what the user see in the app, are you sure?`;
			this.confirmationModalOpen = true;
		},
		selectionConfirmed(type) {
			this.cancel()
			return this.[`upload${type}AndSetActive`]();
		},
		cancel() {
			this.confirmationModalOpen = false;
			this.typeToPrompt = "";
			this.textToPrompt = "";
		},
		clearInputFIeld(type) {
			EventBus.$emit(`clear${type}FileSelect`);
		}
	},
	created() {
		return this.updateDataLists();
	}
};
</script>

<template>
	<div>
		<v-card class="pt-3 px-3">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1">
						Extension updater
						<v-divider class="my-3"/>
					</v-list-item-title>
					<add-extension-list />
				</v-list-item-content>
			</v-list-item>
			<v-card-actions class="mb-2">
				<v-btn
					color="success"
					class="ml-2"
					:disabled="!localExtensions"
					@click="uploadExtension"
				>
					Upload
				</v-btn>
				<v-btn
					color="success"
					class="ml-2"
					:disabled="!localExtensions"
					@click="promptSelection('Extension')"
				>
					Upload and set as active
				</v-btn>
			</v-card-actions>
			<v-divider />
			<v-expansion-panels flat>
				<v-expansion-panel>
					<v-expansion-panel-header expand-icon="fa fa-angle-down">
						HISTORY
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<history :type="phoneMenuKey" />
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<v-card class="pt-3 px-3 mt-5">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="headline mb-1">
						Synonym updater
						<v-divider class="my-3"/>
					</v-list-item-title>
					<add-synonym-list />
				</v-list-item-content>
			</v-list-item>
			<v-card-actions class="mb-2">
				<v-btn
					color="success"
					class="ml-2"
					:disabled="!localSynonyms"
					@click="uploadSynonym"
				>
					Upload
				</v-btn>
				<v-btn
					color="success"
					class="ml-2"
					:disabled="!localSynonyms"
					@click="promptSelection('Synonym')"
				>
					Upload and set as active
				</v-btn>
			</v-card-actions>
			<v-divider />
			<v-expansion-panels flat>
				<v-expansion-panel>
					<v-expansion-panel-header expand-icon="fa fa-angle-down">
						HISTORY
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<history :type="searchListKey" />
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<confirmation-modal
			v-if="confirmationModalOpen"
			:type="typeToPrompt"
			:text="textToPrompt"
			@setActive="selectionConfirmed"
			@cancel="cancel"
		/>
	</div>
</template>

<style scoped>
.km-support-dashboard {
	position: fixed;
}
.mobile-app-container {
	overflow-y: auto;
}
.extension-selector {
	margin-top: 20px;
}
</style>