import Vue from "vue";
import {
	UPDATE_CURRENT_DEPTH,
	UPDATE_EXTENSION,
	CLEAR_EXTENSION,
	ONE_STEP_BACK,
	RESET_SELECTIONS
} from "../mutation-types";

const state = {
	currentDepth: 1,
	selectedExtensions: []
};

// getters
const getters = {
	currentDepth: state => state.currentDepth,
	selectedExtensions: state => state.selectedExtensions
};

// actions
const actions = {
	incrementDepth({ commit }, depth) {
		depth += 1;
		commit(UPDATE_CURRENT_DEPTH, depth);
	},
	updateDepth({ commit }, depth) {
		commit(UPDATE_CURRENT_DEPTH, depth);
	},
	updateExtension({ commit }, extensions) {
		commit(UPDATE_EXTENSION, extensions);
	},
	clearExtension({ commit }) {
		commit(CLEAR_EXTENSION);
	},
	oneStepBack({ commit }) {
		commit(ONE_STEP_BACK);
	},
	resetSelections({ commit }) {
		commit(RESET_SELECTIONS);
	}
};

// mutations
const mutations = {
	[UPDATE_CURRENT_DEPTH](state, depth) {
		state.currentDepth = depth.toString();
	},
	[UPDATE_EXTENSION](state, extensions) {
		state.selectedExtensions = extensions;
	},
	[CLEAR_EXTENSION](state) {
		state.selectedExtensions = [];
	},
	[ONE_STEP_BACK](state) {
		state.currentDepth = state.currentDepth - 1;
		state.selectedExtensions = state.selectedExtensions.slice(0, -1);
	},
	[RESET_SELECTIONS](state) {
		state.currentDepth = 1;
		state.selectedExtensions = [];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
